import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_picker = _resolveComponent("date-picker")!

  return (_openBlock(), _createBlock(_component_date_picker, {
    "input-class": "mx-input px-2",
    type: "date",
    format: "DD.MM.YYYY",
    "value-type": "format",
    placeholder: _ctx.$t(this.placeholder),
    value: _ctx.date,
    "onUpdate:value": _ctx.dateUpdate
  }, null, 8, ["placeholder", "value", "onUpdate:value"]))
}