
import { defineComponent } from "vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";

export default defineComponent({
  name: "SearchInput",
  components: { DefaultButton },
  props: {
    modelValue: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  emits: ["searchUpdate", "searchAction"],
  data: function () {
    return {
      search: this.modelValue ?? "",
    };
  },
  methods: {
    resetSearch() {
      this.search = "";
      this.$emit("searchUpdate", this.search);
      this.$emit("searchAction");
    },
  },
  computed: {
    translatedPlaceholder(): string {
      return this.placeholder ? this.$t(this.placeholder) : "";
    },
  },
  created() {
    if (this.$route.query?.search) {
      this.search = this.$route.query.search as string;
      this.$emit("searchUpdate", this.search);
      this.$emit("searchAction");
    }
  },
});
