
import { defineComponent, ref } from "vue";
import DatePicker from "vue-datepicker-next";

export default defineComponent({
  name: "DatepickerInput",
  components: { DatePicker },
  props: {
    placeholder: {
      type: String,
      default: "DD/MM/YYYY",
    },
    initialDate: {
      type: String,
      default: null,
    },
  },
  emits: ["dateUpdate"],
  setup(props, context) {
    const date = ref(props.initialDate);
    const dateUpdate = (newDate: string) => {
      date.value = newDate;
      context.emit("dateUpdate", date);
    };
    return {
      date,
      dateUpdate,
    };
  },
});
